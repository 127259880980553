import {
  ADMINISTRADOR,
  ADMIN_SYS,
  CONSULTA_FINANCEIRO,
  CONTABIL,
  CONTROLADORIA,
  CREDITO_COBRANCA,
  FATURAMENTO,
  FATURAMENTO_NFE,
  GERENTE_OSORIO,
  LABORATORIO,
  SUPERVISOR_NACIONAL,
  TINTOMETRICO,
} from "@/constants/profiles.js";

export default [
  {
    path: "/pcp",
    name: "pcp",
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        FATURAMENTO_NFE,
        FATURAMENTO,
        LABORATORIO,
        CONSULTA_FINANCEIRO,
        TINTOMETRICO,
        SUPERVISOR_NACIONAL,
      ],
    },
    component: () => import("@/components/home/MenuPCP.vue"),
  },
  {
    path: "/pcp/mrp",
    name: "pcp.mrp",
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        FATURAMENTO_NFE,
        FATURAMENTO,
        LABORATORIO,
        TINTOMETRICO,
        SUPERVISOR_NACIONAL,
      ],
    },
    component: () => import("@/components/home/MenuMRP.vue"),
  },
  {
    path: "/pcp/cadastros",
    name: "pcp.cadastros",
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        FATURAMENTO_NFE,
        FATURAMENTO,
        LABORATORIO,
        TINTOMETRICO,
        SUPERVISOR_NACIONAL,
      ],
    },
    component: () => import("@/components/pcp/cadastros/home-cadastros.vue"),
  },
  {
    path: "/pcp/mrp/gerenciar-mrp",
    name: "pcp.mrp.gerenciar-mrp",
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        FATURAMENTO_NFE,
        LABORATORIO,
        FATURAMENTO,
        TINTOMETRICO,
        SUPERVISOR_NACIONAL,
      ],
    },
    component: () => import("@/components/pcp/mrp/gerenciar-mrp.vue"),
  },
  {
    path: "/pcp/mrp/gerenciar-mrp/show/:id",
    name: "pcp.mrp.gerenciar-mrp.show",
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        FATURAMENTO_NFE,
        LABORATORIO,
        FATURAMENTO,
        TINTOMETRICO,
        SUPERVISOR_NACIONAL,
      ],
    },
    component: () => import("@/components/pcp/mrp/gerenciar-mrp-show.vue"),
  },
  {
    path: "/pcp/formulacoes",
    name: "pcp.formulacoes",
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        FATURAMENTO_NFE,
        FATURAMENTO,
        LABORATORIO,
        CONSULTA_FINANCEIRO,
        TINTOMETRICO,
        SUPERVISOR_NACIONAL,
      ],
    },
    component: () => import("@/components/pcp/formulacoes/home-formulacoes.vue"),
  },
  {
    path: "/pcp/producao",
    name: "pcp.producao",
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        LABORATORIO,
        FATURAMENTO_NFE,
        FATURAMENTO,
        GERENTE_OSORIO,
        CONSULTA_FINANCEIRO,
        TINTOMETRICO,
        SUPERVISOR_NACIONAL,
      ],
    },
    component: () => import("@/components/pcp/producao/home-producao.vue"),
  },
  {
    path: "/pcp/producao/cadastro-producao",
    name: "pcp.cadastro.producao",
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        LABORATORIO,
        FATURAMENTO_NFE,
        FATURAMENTO,
        GERENTE_OSORIO,
        CONSULTA_FINANCEIRO,
        TINTOMETRICO,
        SUPERVISOR_NACIONAL,
      ],
    },
    component: () => import("@/components/pcp/producao/cadastro-producao/cadastro-producao.vue"),
  },
  {
    path: "/pcp/estoque/historico/:codmatpri",
    name: "pcp.estoque.historico",
    component: () => import("@/components/pcp/cadastros/materia-prima/historico-estoque.vue"),
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        FATURAMENTO_NFE,
        FATURAMENTO,
        CONTABIL,
        CREDITO_COBRANCA,
        LABORATORIO,
        GERENTE_OSORIO,
        TINTOMETRICO,
        SUPERVISOR_NACIONAL,
      ],
    },
  },
  {
    path: "/pcp/relatorios",
    name: "pcp.relatorios",
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        FATURAMENTO_NFE,
        FATURAMENTO,
        LABORATORIO,
        CONSULTA_FINANCEIRO,
        TINTOMETRICO,
        SUPERVISOR_NACIONAL,
      ],
    },
    component: () => import("@/components/pcp/relatorios/home-relatorios.vue"),
  },
  {
    path: "/pcp/producao/planejamento",
    name: "pcp.producao.planejamento",
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        FATURAMENTO_NFE,
        FATURAMENTO,
        LABORATORIO,
        CONSULTA_FINANCEIRO,
        TINTOMETRICO,
        SUPERVISOR_NACIONAL,
      ],
    },
    component: () => import("@/components/pcp/relatorios/planejamento-producao.vue"),
  },
  {
    path: "/pcp/producao/gerenciar-cadastro-producao",
    name: "pcp.producao.gerenciar-cadastro-producao",
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        FATURAMENTO_NFE,
        FATURAMENTO,
        LABORATORIO,
        CONSULTA_FINANCEIRO,
        TINTOMETRICO,
        SUPERVISOR_NACIONAL,
      ],
    },
    component: () => import("@/components/pcp/producao/cadastro-producao/gerenciar-cadastro-producao.vue"),
  },
  {
    path: "/pcp/producao/cadastro-hora-produzida/:id",
    name: "pcp.producao.cadastro-hora-produzida",
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        FATURAMENTO_NFE,
        FATURAMENTO,
        LABORATORIO,
        CONSULTA_FINANCEIRO,
        TINTOMETRICO,
        SUPERVISOR_NACIONAL,
      ],
    },
    component: () => import("@/components/pcp/producao/cadastro-producao/cadastro-hora-produzida.vue"),
  },

  {
    path: "/pcp/producao/dashboard-producao",
    name: "pcp.producao.dashboard-producao",
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        FATURAMENTO_NFE,
        FATURAMENTO,
        LABORATORIO,
        CONSULTA_FINANCEIRO,
        TINTOMETRICO,
        SUPERVISOR_NACIONAL,
      ],
    },
    component: () => import("@/components/pcp/producao/cadastro-producao/dashboard-producao.vue"),
  },

  {
    path: "/pcp/producao/cadastro-motivo-parada",
    name: "pcp.producao.cadastro-motivo-parada",
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        FATURAMENTO_NFE,
        FATURAMENTO,
        LABORATORIO,
        CONSULTA_FINANCEIRO,
        TINTOMETRICO,
        SUPERVISOR_NACIONAL,
      ],
    },
    component: () => import("@/components/pcp/producao/cadastro-producao/cadastro-motivo-parada.vue"),
  },
  {
    path: "/pcp/producao/gerenciar-motivo-parada",
    name: "pcp.producao.gerenciar-motivo-parada",
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        FATURAMENTO_NFE,
        FATURAMENTO,
        LABORATORIO,
        CONSULTA_FINANCEIRO,
        TINTOMETRICO,
        SUPERVISOR_NACIONAL,
      ],
    },
    component: () => import("@/components/pcp/producao/cadastro-producao/gerenciar-motivo-parada.vue"),
  },

  {
    path: "/pcp/producao/cadastro-tempo-total-disponivel",
    name: "pcp.producao.cadastro-tempo-total-disponivel",
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        FATURAMENTO_NFE,
        FATURAMENTO,
        LABORATORIO,
        CONSULTA_FINANCEIRO,
        TINTOMETRICO,
        SUPERVISOR_NACIONAL,
      ],
    },
    component: () => import("@/components/pcp/producao/cadastro-producao/cadastro-tempo-total-disponivel.vue"),
  },

  {
    path: "/pcp/producao/analise-paradas",
    name: "pcp.producao.analise-paradas",
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        FATURAMENTO_NFE,
        FATURAMENTO,
        LABORATORIO,
        CONSULTA_FINANCEIRO,
        TINTOMETRICO,
        SUPERVISOR_NACIONAL,
      ],
    },
    component: () => import("@/components/pcp/producao/cadastro-producao/analise-paradas.vue"),
  },

  {
    path: "/pcp/producao/gerenciar-linhas-producao",
    name: "pcp.producao.gerenciar-linhas-producao",
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        FATURAMENTO_NFE,
        FATURAMENTO,
        LABORATORIO,
        CONSULTA_FINANCEIRO,
        TINTOMETRICO,
        SUPERVISOR_NACIONAL,
      ],
    },
    component: () => import("@/components/pcp/producao/cadastro-producao/gerenciar-linhas-producao.vue"),
  },

  {
    path: "/pcp/producao/cadastro-linha-producao",
    name: "pcp.producao.cadastro-linha-producao",
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        FATURAMENTO_NFE,
        FATURAMENTO,
        LABORATORIO,
        CONSULTA_FINANCEIRO,
        TINTOMETRICO,
        SUPERVISOR_NACIONAL,
      ],
    },
    component: () => import("@/components/pcp/producao/cadastro-producao/cadastro-linha-producao.vue"),
  },

  {
    path: "/pcp/cadastros/materia-prima",
    name: "materia-prima",
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        FATURAMENTO_NFE,
        FATURAMENTO,
        LABORATORIO,
        TINTOMETRICO,
        SUPERVISOR_NACIONAL,
      ],
    },
    component: () => import("@/components/pcp/cadastros/materia-prima/materia-prima.vue"),
  },
  {
    path: "/pcp/cadastros/materia-prima/cadastrar",
    name: "pcp.materia-prima.cadastro",
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        FATURAMENTO_NFE,
        FATURAMENTO,
        LABORATORIO,
        TINTOMETRICO,
        SUPERVISOR_NACIONAL,
      ],
    },
    component: () => import("@/components/pcp/cadastros/materia-prima/form-materia-prima.vue"),
  },
  {
    path: "/pcp/cadastros/materia-prima/cadastrar/editar/:id",
    name: "pcp.materia-prima.cadastro.editar",
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        FATURAMENTO_NFE,
        FATURAMENTO,
        LABORATORIO,
        TINTOMETRICO,
        SUPERVISOR_NACIONAL,
      ],
    },
    component: () => import("@/components/pcp/cadastros/materia-prima/form-materia-prima.vue"),
  },
  {
    path: "/pcp/cadastros/materia-prima-grupos",
    name: "pcp.materia-prima.grupos",
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        FATURAMENTO_NFE,
        FATURAMENTO,
        LABORATORIO,
        TINTOMETRICO,
        SUPERVISOR_NACIONAL,
      ],
    },
    component: () => import("@/components/pcp/cadastros/grupo-produto/grupos-materia-prima.vue"),
  },
  {
    path: "/pcp/cadastros/custos-indiretos",
    name: "pcp.custos-indiretos",
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        FATURAMENTO_NFE,
        FATURAMENTO,
        LABORATORIO,
        TINTOMETRICO,
        SUPERVISOR_NACIONAL,
      ],
    },
    component: () => import("@/components/pcp/cadastros/custos-indiretos/custos-indiretos.vue"),
  },
  {
    path: "/pcp/cadastros/total-pago",
    name: "pcp.total-pago",
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        FATURAMENTO_NFE,
        FATURAMENTO,
        LABORATORIO,
        TINTOMETRICO,
        CONTABIL,
        SUPERVISOR_NACIONAL,
      ],
    },
    component: () => import("@/components/pcp/cadastros/custos-indiretos/total-pago.vue"),
  },
  {
    path: "/pcp/cadastros/form-custos-indiretos",
    name: "pcp.form-custos-indiretos",
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        FATURAMENTO_NFE,
        FATURAMENTO,
        LABORATORIO,
        TINTOMETRICO,
        SUPERVISOR_NACIONAL,
      ],
    },
    component: () => import("@/components/pcp/cadastros/custos-indiretos/form-custos-indiretos.vue"),
  },
  {
    path: "/pcp/cadastros/form-custos-indiretos/:id",
    name: "pcp.form-custos-indiretos.edit",
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        FATURAMENTO_NFE,
        FATURAMENTO,
        LABORATORIO,
        TINTOMETRICO,
        SUPERVISOR_NACIONAL,
      ],
    },
    component: () => import("@/components/pcp/cadastros/custos-indiretos/form-custos-indiretos.vue"),
  },
  {
    path: "/pcp/producao/ordem-producao/gerenciar/produto-acabado/emitir",
    name: "pcp.producao.ordem-producao",
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        LABORATORIO,
        FATURAMENTO_NFE,
        FATURAMENTO,
        GERENTE_OSORIO,
        TINTOMETRICO,
        SUPERVISOR_NACIONAL,
      ],
    },
    component: () => import("@/components/pcp/producao/nova-ordem-producao.vue"),
  },
  {
    path: "/pcp/producao/ordem-producao/gerenciar/produto-processo/emitir",
    name: "pcp.producao.ordem-producao-pp",
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        LABORATORIO,
        FATURAMENTO_NFE,
        FATURAMENTO,
        GERENTE_OSORIO,
        TINTOMETRICO,
        SUPERVISOR_NACIONAL,
      ],
    },
    component: () => import("@/components/pcp/producao/nova-ordem-producao-pp.vue"),
  },
  {
    path: "/pcp/producao/ordem-producao/gerenciar",
    name: "pcp.producao.ordem-producao.gerenciar",
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        LABORATORIO,
        FATURAMENTO_NFE,
        FATURAMENTO,
        GERENTE_OSORIO,
        TINTOMETRICO,
        SUPERVISOR_NACIONAL,
      ],
    },
    component: () => import("@/components/pcp/producao/gerenciar-ordem-producao.vue"),
  },
  {
    path: "/pcp/producao/ordem-producao/gerenciar-show/editar/:id",
    name: "pcp.producao.ordem-producao.gerenciar-show.edit",
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        LABORATORIO,
        FATURAMENTO_NFE,
        FATURAMENTO,
        GERENTE_OSORIO,
        TINTOMETRICO,
        SUPERVISOR_NACIONAL,
      ],
    },
    component: () => import("@/components/pcp/producao/gerenciar-ordem-producao-show.vue"),
  },
  {
    path: "/pcp/formulacoes/produto-acabado",
    name: "pcp.formulacoes-produtos",
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        LABORATORIO,
        CONSULTA_FINANCEIRO,
        TINTOMETRICO,
        SUPERVISOR_NACIONAL,
      ],
    },
    component: () => import("@/components/pcp/formulacoes/formulacoes-produtos.vue"),
  },
  {
    path: "/pcp/formulacoes/produto-processo",
    name: "pcp.formulacoes-materia-prima",
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        LABORATORIO,
        CONSULTA_FINANCEIRO,
        TINTOMETRICO,
        SUPERVISOR_NACIONAL,
      ],
    },
    component: () => import("@/components/pcp/formulacoes/formulacoes-materia-prima.vue"),
  },
  {
    path: "/pcp/relatorios/custos-totais-estoque",
    name: "pcp.relatorios.materia-prima.custo",
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        LABORATORIO,
        CONSULTA_FINANCEIRO,
        TINTOMETRICO,
        SUPERVISOR_NACIONAL,
      ],
    },
    component: () => import("@/components/pcp/relatorios/materia-prima-custo.vue"),
  },
  {
    path: "/pcp/relatorios/consumo-materia-prima-producao",
    name: "pcp.relatorios.consumo.materia.prima.producao",
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        LABORATORIO,
        CONSULTA_FINANCEIRO,
        TINTOMETRICO,
        SUPERVISOR_NACIONAL,
      ],
    },
    component: () => import("@/components/pcp/relatorios/consumo-producao.vue"),
  },
  {
    path: "/pcp/relatorios/previsao-producao",
    name: "pcp.relatorios.producao.previsao",
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        LABORATORIO,
        CONSULTA_FINANCEIRO,
        TINTOMETRICO,
        SUPERVISOR_NACIONAL,
      ],
    },
    component: () => import("@/components/pcp/relatorios/previsao-producao.vue"),
  },
  {
    path: "/pcp/formulacoes/produto-show/:id",
    name: "pcp.formulacoes.produto-show",
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        FATURAMENTO_NFE,
        FATURAMENTO,
        LABORATORIO,
        TINTOMETRICO,
        SUPERVISOR_NACIONAL,
      ],
    },
    component: () => import("@/components/estoque/cadastros/produto/form-produto.vue"),
  },
  {
    path: "/pcp/relatorios/pcp/custo",
    name: "pcp.relatorios.pcp.custo",
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        FATURAMENTO_NFE,
        FATURAMENTO,
        LABORATORIO,
        TINTOMETRICO,
        SUPERVISOR_NACIONAL,
      ],
    },
    component: () => import("@/components/pcp/relatorios/produtos-custo.vue"),
  },
  {
    path: "/pcp/mrp/demandas",
    name: "pcp.mrp.demandas",
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        FATURAMENTO_NFE,
        FATURAMENTO,
        LABORATORIO,
        CONSULTA_FINANCEIRO,
        TINTOMETRICO,
        SUPERVISOR_NACIONAL,
      ],
    },
    component: () => import("@/components/pcp/mrp/DemandasMRP.vue"),
  },
  {
    path: "/pcp/mrp/demandas/novo",
    name: "pcp.mrp.demandas.novo",
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        FATURAMENTO_NFE,
        FATURAMENTO,
        LABORATORIO,
        CONSULTA_FINANCEIRO,
        TINTOMETRICO,
        SUPERVISOR_NACIONAL,
      ],
    },
    component: () => import("@/components/pcp/mrp/NovaDemandaMRP.vue"),
  },
  {
    path: "/pcp/mrp/gerenciar/novo",
    name: "pcp.mrp.gerenciar.novo",
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        FATURAMENTO_NFE,
        FATURAMENTO,
        LABORATORIO,
        CONSULTA_FINANCEIRO,
        TINTOMETRICO,
        SUPERVISOR_NACIONAL,
      ],
    },
    component: () => import("@/components/pcp/mrp/NovoMRP.vue"),
  },
  {
    path: "/pcp/cadastros/etapas",
    name: "pcp.producao.etapas.gerenciar-etapas",
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        LABORATORIO,
        FATURAMENTO_NFE,
        FATURAMENTO,
        GERENTE_OSORIO,
        TINTOMETRICO,
        SUPERVISOR_NACIONAL,
      ],
    },
    component: () => import("@/components/pcp/producao/etapas/gerenciar-etapas.vue"),
  },

  {
    path: "/pcp/cadastros/maquinas",
    name: "pcp.producao.etapas.cadastro-maquinas",
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        LABORATORIO,
        FATURAMENTO_NFE,
        FATURAMENTO,
        GERENTE_OSORIO,
        TINTOMETRICO,
        SUPERVISOR_NACIONAL,
      ],
    },
    component: () => import("@/components/pcp/producao/etapas/cadastro-maquinas.vue"),
  },
  {
    path: "/pcp/cadastros/ferramentas",
    name: "pcp.producao.etapas.cadastro-ferramentas",
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        LABORATORIO,
        FATURAMENTO_NFE,
        FATURAMENTO,
        GERENTE_OSORIO,
        TINTOMETRICO,
        SUPERVISOR_NACIONAL,
      ],
    },
    component: () => import("@/components/pcp/producao/etapas/cadastro-ferramentas.vue"),
  },
  {
    path: "/pcp/cadastros/operadores",
    name: "pcp.producao.etapas.cadastro-operadores",
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        LABORATORIO,
        FATURAMENTO_NFE,
        FATURAMENTO,
        GERENTE_OSORIO,
        TINTOMETRICO,
        SUPERVISOR_NACIONAL,
      ],
    },
    component: () => import("@/components/pcp/producao/etapas/cadastro-operadores.vue"),
  },
  {
    path: "/pcp/configuracoes",
    name: "pcp.configuracoes",
    meta: {
      authorize: [
        ADMINISTRADOR,
        ADMIN_SYS,
        CONTROLADORIA,
        LABORATORIO,
        CONSULTA_FINANCEIRO,
        TINTOMETRICO,
        SUPERVISOR_NACIONAL,
      ],
    },
    component: () => import("@/components/pcp/configuracoes/home-configuracoes.vue"),
  },
];
