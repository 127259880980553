import {
  ADMINISTRADOR,
  CONSULTA_FINANCEIRO,
  CONTROLADORIA,
  EMISSOR_NFE,
  FATURAMENTO_NFE,
  FINANCEIRO_GERENTE,
  GESTAO,
  OPERACIONAL,
  SUPERVISOR,
  PUBLICIDADE,
} from "@/constants/profiles.js";

const routes = [
  {
    path: "/crm",
    name: "crm.home",
    meta: {
      authorize: [
        ADMINISTRADOR,
        CONSULTA_FINANCEIRO,
        CONTROLADORIA,
        FATURAMENTO_NFE,
        GESTAO,
        OPERACIONAL,
        EMISSOR_NFE,
        FINANCEIRO_GERENTE,
        SUPERVISOR,
        PUBLICIDADE,
      ],
    },
    component: () => import("@/components/crm/MenuCRM.vue"),
  },
  {
    path: "/crm/negocios",
    name: "crm.negocios",
    meta: { authorize: [FATURAMENTO_NFE, EMISSOR_NFE, GESTAO, OPERACIONAL, PUBLICIDADE] },
    component: () => import("@/components/crm/negocios/kanban.vue"),
  },
  {
    path: "/crm/negocios/:id",
    name: "crm.negocios.edit",
    meta: { authorize: [FATURAMENTO_NFE, EMISSOR_NFE, GESTAO, OPERACIONAL, PUBLICIDADE] },
    component: () => import("@/components/crm/negocios/edit.vue"),
  },
  {
    path: "/crm/negocios/leads",
    name: "crm.negocios.leads",
    meta: {
      authorize: [
        ADMINISTRADOR,
        CONSULTA_FINANCEIRO,
        CONTROLADORIA,
        FATURAMENTO_NFE,
        FINANCEIRO_GERENTE,
        SUPERVISOR,
        EMISSOR_NFE,
        GESTAO,
        OPERACIONAL,
        PUBLICIDADE,
      ],
    },
    component: () => import("@/components/crm/negocios/leads.vue"),
  },
  {
    path: "/crm/negocios/relatorios",
    name: "crm.negocios.relatorios",
    meta: { authorize: [FATURAMENTO_NFE, EMISSOR_NFE, GESTAO, OPERACIONAL, PUBLICIDADE] },
    component: () => import("@/components/crm/negocios/relatorios.vue"),
  },
  {
    path: "/crm/rdstation",
    name: "crm.rdstation",
    component: () => import("@/pages/crm/IntegracaoRDStation.vue"),
    meta: { requiresAuth: true },
  },
];

export default routes;
