import { ref } from "vue";
import axios from "axios";

const settings = ref<Record<string, any>>({});

export default {
  state: settings,

  async fetchSettings() {
    const { data } = await axios.get("/api/system-settings");
    settings.value = data;
  },

  getSetting(key: string) {
    return settings.value[key];
  },
};
