import {
  ADMIN_SYS,
  ADMINISTRADOR,
  COMPRAS,
  CONTABIL,
  CONTROLADORIA,
  CREDITO_COBRANCA,
  FATURAMENTO_NFE,
  FATURAMENTO,
  FINANCEIRO,
  LOGISTICA,
  SUPERVISOR,
  FINANCEIRO_GERENTE,
} from "@/constants/profiles.js";

export default [
  {
    component: () => import("@/components/faturamento/cadastros/representante/editor-representante.vue"),
    meta: { authorize: [ADMIN_SYS, ADMINISTRADOR, CONTROLADORIA, FINANCEIRO, FINANCEIRO_GERENTE] },
    name: "representantes.cadastro",
    path: "/equipe/cadastros/representantes/cadastro",
  },
  {
    component: () => import("@/components/faturamento/cadastros/representante/editor-representante.vue"),
    meta: { authorize: [ADMIN_SYS, ADMINISTRADOR, CONTROLADORIA, FINANCEIRO, FINANCEIRO_GERENTE] },
    name: "representantes.edit",
    path: "/equipe/cadastros/representantes/editar/:id",
  },
  {
    component: () => import("@/components/equipe/MenuEquipe.vue"),
    meta: {
      authorize: [ADMIN_SYS, ADMINISTRADOR, COMPRAS, CONTABIL, CONTROLADORIA, FINANCEIRO_GERENTE, FATURAMENTO_NFE],
    },
    name: "equipe",
    path: "/equipe",
  },
  {
    component: () => import("@/components/faturamento/vendas/supervisor/minha-equipe.vue"),
    meta: { authorize: [ADMIN_SYS, ADMINISTRADOR, CONTROLADORIA, SUPERVISOR, FINANCEIRO_GERENTE] },
    name: "vendas.supervisor.equipe",
    path: "/equipe/cadastros/equipe-vendas",
  },
  {
    component: () => import("@/components/faturamento/cadastros/representante/representantes.vue"),
    meta: { authorize: [ADMIN_SYS, ADMINISTRADOR, CONTROLADORIA, FINANCEIRO, FINANCEIRO_GERENTE, FATURAMENTO_NFE] },
    name: "representantes",
    path: "/equipe/cadastros/representantes/gerenciar",
  },
  {
    component: () => import("@/components/faturamento/cadastros/representante-grupo/representante-grupos.vue"),
    meta: {
      authorize: [
        ADMIN_SYS,
        ADMINISTRADOR,
        CONTABIL,
        CONTROLADORIA,
        CREDITO_COBRANCA,
        FINANCEIRO,
        LOGISTICA,
        FINANCEIRO_GERENTE,
      ],
    },
    path: "/equipe/cadastros/representantes/grupos",
  },
  {
    component: () => import("@/components/equipe/SubmenuEquipeCadastros.vue"),
    meta: { authorize: [ADMIN_SYS, ADMINISTRADOR, COMPRAS, CONTABIL, CONTROLADORIA, FINANCEIRO_GERENTE] },
    name: "equipe.cadastros",
    path: "/equipe/cadastros",
  },
];
