<template>
  <div :data-loading="isLoading">
    <Notifications />
    <RouterView />
  </div>
</template>

<script setup>
import { computed, onMounted } from "vue";
import appStore from "@/store/app.js";
import authStore from "@/store/auth.js";
import emitenteStore from "@/store/emitente.js";
import settingsStore from "@/store/settings";

const isLoading = computed(() => appStore.loadingCount > 0);

onMounted(async () => {
  appStore.state.loadingCount += 1;
  if (authStore.isAuthenticated()) {
    await Promise.all([
      authStore.currentUserRequest(),
      settingsStore.fetchSettings(),
      emitenteStore.getEmitenteId() ? emitenteStore.fetchEmitenteFromCookie() : null,
    ]);
  }
  appStore.state.loadingCount -= 1;
  appStore.state.mounted = true;
});
</script>

<style src="./style.scss" lang="scss"></style>
